import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`A Guides for Contribute and Request`}</h2>
    <p>{`Learn how to contribute and make request to Legion Design System`}</p>
    <h3><strong parentName="h3">{`Report an Issue`}</strong></h3>
    <p>{`Empower you to provide valuable feedback, report bugs, and suggest improvements, ensuring ongoing refinement and seamless collaboration`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Found a bug or issue with Legion’s Website? Report it in our `}<a parentName="p" {...{
            "href": "https://gitlab.playcourt.id/telkomdev/legion-phoenix/-/issues?sort=created_date&state=opened"
          }}><strong parentName="a">{`GitLab PlayCourt for Website`}</strong></a>{` `}<br />{` `}<br /></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Found a bug or issue with Legion Android? Report it in our `}<a parentName="p" {...{
            "href": "https://gitlab.playcourt.id/mobileteam/legion-android/-/issues"
          }}><strong parentName="a">{`GitLab PlayCourt for Android`}</strong></a>{` `}<br />{` `}<br /></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Found a bug or issue with Legion iOS? Report it in our `}<a parentName="p" {...{
            "href": "https://gitlab.playcourt.id/mobileteam/legion-ios/-/issues/"
          }}><strong parentName="a">{`GitLab PlayCourt for IOS`}</strong></a>{` `}<br />{` `}<br /></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Found a bug or issue with Legion Design Library? Report with comment the issue in legion figma project `}<br />{` `}<br /></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In addition, you can also inform the issue to us in `}<a parentName="p" {...{
            "href": "https://discord.com/channels/1033975536227201044/1034387787832631418"
          }}><strong parentName="a">{`#issue-report`}</strong></a>{` text channel on our Discord server. Make sure you’ve been verified before submitting your issue, by providing all the necessary credentials inside the `}<a parentName="p" {...{
            "href": "https://discord.gg/DDJrRdjjQJ"
          }}><strong parentName="a">{`#verification`}</strong></a>{` text channel `}<br /></p>
      </li>
    </ul>
    <div className="divi" />
    <h3><strong parentName="h3">{`Request Access Design or Code Library`}</strong></h3>
    <p>{`Gain exclusive access to a vast collection of meticulously crafted design assets or meticulously written code snippets, enabling you to accelerate your development process and create stunning, polished experiences`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Begin by completing the Design Systems Submission Form and we will provide you with the necessary information and links to submit a new assets (Component, Pattern, etc) proposal or request for usage development library from `}<a parentName="p" {...{
            "href": "https://forms.gle/vNvoJ5t6FDM7D1VD8"
          }}><strong parentName="a">{`this link request`}</strong></a>{` `}<br />{` `}<br /></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`For minor updates and incremental changes to components, contact the `}<a parentName="p" {...{
            "href": "https://discord.com/channels/1033975536227201044/1034027795623465060"
          }}><strong parentName="a">{`Legion Design Systems team`}</strong></a>{` on our Discord channels with hashtag `}<a parentName="p" {...{
            "href": "https://discord.com/channels/1033975536227201044/1034027795623465060"
          }}><strong parentName="a">{`#component-pattern-team`}</strong></a>{` or schedule a meeting during the Office Hours`}</p>
      </li>
    </ul>
    <div className="divi" />
    <h3><strong parentName="h3">{`Make an Edit or Add Content in Legion`}</strong></h3>
    <p>{`Make your mark on Legion by suggesting edits or adding valuable content. Join our community of contributors and help shape the rich tapestry of knowledge within Legion. Share your insights, expertise, and ideas to enhance the depth and breadth of information available, ensuring that Legion remains an invaluable resource for all.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`We welcome contributions of any size in Legion! If you’re interested in contributing, please reach out to `}<strong parentName="p"><a parentName="strong" {...{
              "href": "mailto:legion@telkom.design"
            }}>{`legion@telkom.design`}</a></strong>{` and we’ll be happy to provide you with the necessary resources and assist you in the contribution process `}<br />{` `}<br /></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Alternatively, you can also directly mention us `}<strong parentName="p">{`@Content Team`}</strong>{` in `}<a parentName="p" {...{
            "href": "https://discord.gg/Yfz5uBCrFP"
          }}><strong parentName="a">{`#general`}</strong></a>{` text channel on Legion Discord server `}<br /></p>
      </li>
    </ul>
    <div className="divi" />
    <h3><strong parentName="h3">{`Collaborate with Legion Team`}</strong></h3>
    <p>{`Do you have any questions or suggestions for improvements to the Legion Design System (such as new components, patterns, or documentation)? Do you need any other assistance or guidance on using the system? We are here to help! Bring your queries and ideas to our office hours, which are a casual and informal setting and you do not have to make a formal presentation`}</p>
    <p>{`Meet and contact us during office hours Monday-Friday from 8am-5pm in `}<a parentName="p" {...{
        "href": "https://discord.com/channels/1033975536227201044/1033975536868917340"
      }}><strong parentName="a">{`#general`}</strong></a>{` on Discord`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      